<template>
  <div>
    <v-card width="500" class="mx-auto">
      <v-card-title>
        <v-icon medium>person</v-icon>
        <span class="headline">
          <b>إضافة مستخدم</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الاسم الكامل"
                  placeholder="الاسم الكامل"
                  v-model="user.fullName"
                  :rules="fullNameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الإيميل"
                  type="email"
                  placeholder="الإيميل"
                  v-model="user.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الموبايل"
                  placeholder="00966011XXXXXX"
                  v-model="user.phoneNumber"
                  :rules="phoneNumberRules"
                  length="14"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="كلمة السر"
                  placeholder="كلمة السر"
                  type="password"
                  v-model="user.password"
                  :rules="passwordRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label=" تأكيد كلمة السر"
                  type="password"
                  placeholder=" تأكيد كلمة السر"
                  v-model="confirmPassword"
                  :rules="[
                    !!confirmPassword || 'type confirm password',
                    user.password === confirmPassword ||
                      'The password confirmation does not match.'
                  ]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-select
                  v-model="selectRole"
                  :items="roles"
                  item-text="item"
                  item-value="value"
                  label="دور المستخدم"
                  :rules="rolesRules"
                  persistent-hint
                  return-object
                  requi
                  red
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="save()"
            style="background:#5867dd;color:white;margin-left:5px;"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background:gray;" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      confirmPassword: "",
      rolesRules: [
        v => !!v || "دور المستخدم مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      fullNameRules: [
        v => !!v || "الاسم الكامل مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      nameRules: [
        v => !!v || "اسم المستخدم مطلوب",
        v =>
          (v && v.length >= 3) ||
          "اسم المستخدم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      emailRules: [
        v => !!v || "الإيميل مطلوب",
        v => /.+@.+\..+/.test(v) || "أدخل إيميل صحيح"
      ],
      passwordRules: [
        v => !!v || "كلمة السر مطلوبة",
        v =>
          (v &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
              v
            )) ||
          "كلمة المرور يجب أن لا تقل عن 8 محارف معقدة وتحوي أرقام, أحرف صغيرة, كبيرة ورموز"

        //  v => /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{10,25})$ /|| 'كلمة المرور يجب أن يحوي محارف كبيرة وصغيرة ورموز'
      ],
      phoneNumberRules: [
        v => !!v || "رقم الموبايل مطلوب",
        v =>
          (v && (v.length == 9 || v.length == 10 || v.length == 14)) ||
          "رقم الموبايل يجب أن يكون بطول 9 أو 10 أو 14 أرقام"
      ],
      passwordConfirmRules: [
        v => !!v || "تأكيد كلمة السر مطلوب",
        v =>
          v == this.user.password || "كلمة السر وتأكيد كلمة السر غير متطابقان"
        //  this.confirmPassword == this.user.password || 'كلمة السر وتأكيد كلمة السر غير متطابقان',
      ],
      editInfo: false,
      RoleSelected: "0",
      user: {
        password: "",
        fullName: "",
        userName: "",
        email: "",
        phoneNumber: "",
        responsibleId: this.RoleSelected
      },
      selectRole: "",
      roles: [
        { item: "اختر دور المستخدم", value: "0" },
        { item: "مندوب مبيعات", value: "Salesman" },
        { item: "مشرف", value: "Supervisor" },
        { item: "مدير الإدارة", value: "Director" },
        { item: "رئيس قسم", value: "General Manager Of Dept" },
        { item: "أدمن", value: "Admin" },
        { item: "مدير عام", value: "CEO" }
      ]
    };
  },
  created() {},

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.ApiService.post("Auth/Register", {
          userName: this.user.email,
          e_mail: this.user.email,
          password: this.user.password,
          role: this.selectRole.value,
          fullName: this.user.fullName,
          phoneNumber: this.user.phoneNumber
        })
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.push({ name: "users" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    getUser() {}
  }
};
</script>
<style scoped>
label .v-label .theme--light {
  left: auto;
  right: 0px;
  position: absolute;
}
</style>
